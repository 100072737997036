.component__login {
    width: 100%;
    height: 100vh;
    background-image: url(../../../public/images/loginbcg.png);
    background-size: cover;
    background-position: center;
    top: 0px;
    position: fixed;
    &__wraper {
        width: 100%;
        height: 100%;
        background: linear-gradient(81.5deg, #272737 13%, rgba(39, 39, 55, 0.43) 87%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &>img{
            position: absolute;
            top: 10px;
            left: 30px;
            @media screen and (max-width: 700px) {
                width: 200px;
                left: calc(50% - 100px);
                
            }
        }
        &__form {
            height: 565px;
            width: 479px;
            border-radius: 5px;
            background-color: #272737;
            max-width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            padding: 60px;
            @media screen and (max-width: 700px) {
                height: calc(100% - 100px);
                max-height: 470px;
                padding: 40px 20px;
            }
            & > div {
                width: 100%;
            }
            & > div > p {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: #999fae;
            }
            & > div > h1 {
                font-family: Hahmlet;
                font-size: 32px;
                font-weight: 700;
                line-height: 46px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
            }
            & > div > label {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                margin-bottom: 12px;
            }
            & > div > input {
                width: 100%;
                outline: none;
                border: none;
                border-bottom: 1px solid #e8e8e8;
                background-color: transparent;
                padding: 12px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
            }
            &__errorMsg {
                opacity: 0;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;
                color: red;
                text-align: center;
                width: 100%;
            }
            & > button {
                height: 50px;
                width: 100%;
                border-radius: 61px;
                cursor: pointer;
                background-color: #6664e5;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                letter-spacing: 0em;
                color: white;
                border: none;
            }
        }
    }
}
