.component__menu {
    width: 100%;
    height: 100vh;
    background-image: url(../../../public/images/loginbcg.png);
    background-size: cover;
    background-position: center;
    top: 0px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        & > p {
            position: absolute;
            top: 20px;
            left: 20px;
            background-color: #6664e5;
            padding: 10px;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            font-size: 18px;
            cursor: pointer;
            font-family: Roboto;
        }

        & > button {
            height: 50px;
            width: 347px;
            border-radius: 61px;
            margin-bottom: 10px;
            background-color: #6664e5;
            color: white;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            border: none;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover{
                background-color: #4441e0;
            }
        }
        & > button:last-child {
            background-color: red;
            margin-left: auto;
            margin-right: 20px;
            transition: 0.3s ease;
            &:hover{
                background-color: #b30909;
            }
            @media screen and (max-width: 1000px) {
                margin: 40px auto;
            }
        }
    }
}
.component__main {
    width: 100%;
    height: 100%;
    flex-direction: column;
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
        & > p {
            position: absolute;
            top: 20px;
            left: 20px;
            background-color: #6664e5;
            padding: 10px;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            font-size: 18px;
            cursor: pointer;
            font-family: Roboto;
        }

        & > button {
            height: 50px;
            width: 347px;
            border-radius: 61px;
            margin-bottom: 10px;
            background-color: #6664e5;
            color: white;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            border: none;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover{
                background-color: #4441e0;
            }
        }
    }
    &__dashboard {
        @media screen and (max-width: 1000px) {
            display: none;
            
        }
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
        &__kpi {
            width: 300px;
            height: 200px;
            background-color: #2b2c3e;
            padding: 20px;
            & > p {
                color: white;
                text-align: center;
                font-family: Figtree;
                font-size: 22px;
                font-weight: 900;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
            }
        &>p:last-child{
            color: white;
                text-align: center;
                font-family: Figtree;
                font-size: 36px;
                font-weight: 900;
                line-height: 40px;
                letter-spacing: 0em;
                text-align: center;
                margin-top: 20px;
        }
        }
    }
}
