.component__transfer__container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    & > p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 12px;
        width: 100%;
        cursor: pointer;
    }

    & > button {
        height: 50px;
        width: 347px;
        border-radius: 61px;
        margin-bottom: 10px;
        background-color: #6664e5;
        color: white;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        border: none;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover{
            background-color: #4441e0;
        }
    }
    &__users{
        width: 100%;
        height: 100%;
        max-width: 576px;
        max-height: 576px;
        overflow: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__item{
            height: 50px;
            width: 347px;
            border-radius: 61px;
            margin-bottom: 10px;
            border: 1px solid #6664e5;
            color: white;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0em;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s ease;
            &:hover{
                background-color: #6664e5;
            }
        }
        &__item--active{
            background-color: #a1a0eb;
        }
    }
    .component__transfer__container__transfer-text{
        text-align: center;
        font-size: 20px;
    }
}

.component__login__wraper__goback{
    transition: 0.3s ease;
    &:hover{
        background-color: #b30909;
    }
}
