.component__menu__container {
    width: 479px;
    border-radius: 5px;
    background-color: #272737;
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 60px;
    &>div{
        width: 100%;
    }
    @media screen and (max-width: 700px) {
        padding: 40px 20px;
    }
    &>p{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 12px;
        width: 100%;
        cursor: pointer;
    }
    & > div > input {
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid #e8e8e8;
        background-color: transparent;
        padding: 12px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 20px;
        font-family: Roboto;
        margin-top: 10px;
    }
    & > div > textarea{
        width: 100% !important;
        outline: none;
        border: 1px solid #e8e8e8;
        background-color: transparent;
        padding: 12px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 20px;
        font-family: Roboto;
        margin-top: 10px;
    }
    & > div > label {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        margin-bottom: 12px;
        width: 100%;
    }
    & > button {
        height: 50px;
        width: 347px;
        border-radius: 61px;
        margin-bottom: 10px;
        background-color: #6664e5;
        color: white;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        border: none;
        cursor: pointer;
        max-width: 100%;
        transition: 0.3s ease;
        &:hover{
            background-color: #4441e0;
        }
        @media screen and (max-width: 700px) {
            width: 100%;
        }
    }
}

.component__login__wraper__goback{
    transition: 0.3s ease;
    &:hover{
        background-color: #4441e0;
    }
}
