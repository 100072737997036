* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body, #root {
  width: 100%;
  height: 100%;
}
#root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:1fr;
}