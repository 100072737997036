.tableContainer {
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    align-self: flex-end;
    margin-top: 50px;

    &__dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
    & .customComponents__table__data__groupNewline > p {
        width: 100%;
    }
}
.contacts {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: #272737;
    text-align: center;
    > p {
        color: white;
        width: 15%;
        padding: 20px;
        font-family: Roboto;
        word-break: break-all;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-left: 20px;
        }
        & > button {
            font-family: Roboto;
            background-color: #6664e5;
            padding: 10px;
            border-radius: 5px;
            color: white;
            cursor: pointer;
            font-size: 12px;
            border: none;
        }
        @media screen and (max-width: 700px) {
            width: 33%;
        }
    }

    > .contactDescription {
        width: 55% !important;
        @media screen and (max-width: 700px) {
            display: none;
        }
    }
}
.component__edit {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #27273798;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
        width: 479px;
        border-radius: 5px;
        background-color: #272737;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 60px;
        &>p{
            color: white;
            font-family: Roboto;
            margin-bottom: 15px;
            &>button{
                background-color: #6664e5;
                border: none;
                padding: 5px 10px ;
                border-radius: 5px;
                cursor: pointer;
                margin-left: 10px;
                color: white;
            }
        }
        &>div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            &>button{
                background-color: #6664e5;
                border: none;
                padding: 5px 10px ;
                border-radius: 5px;
                cursor: pointer;
                margin: 10px;
                color: white;
            }
            &>button:last-child{
                background-color: red;
                transition: 0.3s ease;
                &:hover{
                    background-color: #b30909;
                }
            }
        }
    }
}
.contactImage{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &>img{
        min-width: 100px;
        height: auto;
        max-width: 400px;
    }
}

.component__login__wraper__goback{
    transition: 0.3s ease;
    &:hover{
        background-color: #4441e0;
    }
}